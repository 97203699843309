// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/primitives/OperatingSystemProvider.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/primitives/OperatingSystemProvider.tsx");
  import.meta.hot.lastModified = "1721054464786.9304";
}
// REMIX HMR END

import { useContext } from "react";
import { createContext } from "react";
const Context = createContext(null);
export const OperatingSystemContextProvider = ({
  platform,
  children
}) => {
  return <Context.Provider value={{
    platform
  }}>{children}</Context.Provider>;
};
_c = OperatingSystemContextProvider;
const throwIfNoProvider = () => {
  throw new Error("Please wrap your application in an OperatingSystemContextProvider.");
};
export const useOperatingSystem = () => {
  _s();
  const {
    platform
  } = useContext(Context) ?? throwIfNoProvider();
  return {
    platform
  };
};
_s(useOperatingSystem, "gDsCjeeItUuvgOWf1v4qoK9RF6k=");
var _c;
$RefreshReg$(_c, "OperatingSystemContextProvider");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;